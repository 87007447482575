import baguetteBox from 'baguettebox.js'

import {Swiper, Pagination, Navigation, FreeMode} from 'swiper'

class BriefList {
    constructor() {
        this.transparentClass = 'transparent'
        this.briefClass = 'brief-container'

        this.slides = document.querySelectorAll('.brief-list .brief-wrapper')
        this.btnPrev =  document.querySelector('.brief-list-container .button-prev-container .button')
        this.btnNext = document.querySelector('.brief-list-container .button-next-container .button')

        this.pointLeft = null
        this.pointRight = null

        this.slideLeft = null
        this.slideRight = null

        this.setSliderState(20)
        window.addEventListener('resize', () => {
            // TODO: before setSliderState, make sure that swiper translation/transition isn't running
            this.setSliderState(20)
        })

        this.initSwiper()
    }

    initSwiper() {
        const swiper = new Swiper(document.querySelector('.brief-list-box'), {
            modules: [Navigation],
            slidesPerView: 'auto',
            navigation: { prevEl: this.btnPrev, nextEl: this.btnNext}
        });

        swiper.on('setTranslate', this._handleSliding.bind(this))
        swiper.on('setTransition', (swiper, dur) => {
            if (0 === dur) return

            requestAnimationFrame(this._makeRaFCb(null, dur, this._handleSliding.bind(this), this._makeRaFCb.bind(this)))
        })

        console.log(swiper);
    }

    _makeRaFCb(timeInitial, dur, cb, makeRaFCb) {
        return (time) => {
            if (null === timeInitial) timeInitial = time
            if (dur < time - timeInitial) {
                // console.log('rAfCb, more time has passed than the given duration - dur, time - timeInitial:', dur, time - timeInitial);
                return
            }

            cb()
            requestAnimationFrame(makeRaFCb(timeInitial, dur, cb, makeRaFCb))
        }
    }

    setSliderState(offset) {
        this.setPoints(offset)
        this._setInitialSlides()
        this._setSlidesState()
    }

    _handleSliding() {
        // console.log('initBriefList, swiper setTranslate cb, translate:', translate);
        if (this.isBehindLeft(this.pointLeft, this.slideLeft.querySelector(`.${this.briefClass}`))) {
            // console.log('initBriefList, swiper setTranslate cb, leftSlide is behind, leftSlide:', leftSlide);
            if (!this.slideLeft.classList.contains(this.transparentClass)) this.slideLeft.classList.add(this.transparentClass)
            if (this.slideLeft.nextElementSibling && this.isBehindLeft(this.pointLeft, this.slideLeft.nextElementSibling.querySelector(`.${this.briefClass}`))) this.slideLeft = this.slideLeft.nextElementSibling
        } else {
            // console.log('initBriefList, swiper setTranslate cb, leftSlide isnt behind, leftSlide:', leftSlide);
            if (this.slideLeft.classList.contains(this.transparentClass)) this.slideLeft.classList.remove(this.transparentClass)
            this.slideLeft = this.slideLeft.previousElementSibling || this.slideLeft
        }

        if (this.isBehindRight(this.pointRight, this.slideRight.querySelector(`.${this.briefClass}`))) {
            // console.log('initBriefList, swiper setTranslate cb, rightSlide is behind, rightSlide:', rightSlide);
            if (!this.slideRight.classList.contains(this.transparentClass)) this.slideRight.classList.add(this.transparentClass)
            if (this.slideRight.previousElementSibling && this.isBehindRight(this.pointRight, this.slideRight.previousElementSibling.querySelector(`.${this.briefClass}`))) this.slideRight = this.slideRight.previousElementSibling
        } else {
            // console.log('initBriefList, swiper setTranslate cb, slideRight isnt behind, slideRight:', slideRight);
            if (this.slideRight.classList.contains(this.transparentClass)) this.slideRight.classList.remove(this.transparentClass)
            this.slideRight = this.slideRight.nextElementSibling || this.slideRight
        }
    }

    // private, because depends on pointLeft/pointRight being set
    _setSlidesState() {
        for (const slide of this.slides) {
            if (this.isBehindLeft(this.pointLeft, slide.querySelector(`.${this.briefClass}`))) {
                slide.classList.add(this.transparentClass)
            } else if (this.isBehindRight(this.pointRight, slide.querySelector(`.${this.briefClass}`))) {
                slide.classList.add(this.transparentClass)
            } else {
                if (slide.classList.contains(this.transparentClass)) slide.classList.remove(this.transparentClass)
            }
        }
    }

    // private, because depends on pointLeft/pointRight being set
    _setInitialSlides() {
        this.slideLeft = this._getClosest(this.pointLeft, this.slides, true)
        this.slideRight = this._getClosest(this.pointRight, this.slides)
    }

    setPoints(offset) {
        this.pointLeft = this.btnPrev.getBoundingClientRect().right - offset
        this.pointRight = this.btnNext.getBoundingClientRect().left + offset
    }

    _getClosest(point, slides, left) {
        let [prevD, prevEl] = [null, null]

        for (const slide of slides) {
            const d = Math.abs(point - left ? slide.querySelector(`.${this.briefClass}`).getBoundingClientRect().left : slide.querySelector(`.${this.briefClass}`).getBoundingClientRect().right)
            if (null === prevD || d < prevD) { prevD = d; prevEl = slide; continue }
            return prevEl
        }
    }

    isBehindLeft(point, el) {
        return el.getBoundingClientRect().left < point
    }

    isBehindRight(point, el) {
        return el.getBoundingClientRect().right > point
    }
}

class FT {
  constructor () {
    document.addEventListener('DOMContentLoaded', () => {
        this.breakpointOne = 1110
        this.ua = true
        this.en = false

        this.BriefList = BriefList

        this.initMenuSwitch()
        this.initToTop()

        // -- temporarily disabled ---
        // this.initSearchSwitch()

        this.forPageType('home', () => {
          this.initMainSlider()
          this.initSuccinctSlider()
        })

        this.forPageType('show', () => {
          this.initShowDates()
        })

        this.forPageType('person', () => {
          this.initPersonBio()
        })
    })

    window.addEventListener('load', () => {
        this.forPageType('show', () => {
          this.initBriefList()
          this.initPhotoScroll()
        })

        this.forPageType('person', () => {
          this.initBriefList()
          this.initPhotoScroll()
        })
    })
  }

  forPageType (type, func) {
    if (document.body.classList.contains(`page-type-${type}`)) {
      func.bind(this)()
    }
  }

  initToTop() {
      const btn = document.querySelector('.page > .to-top')
      const test = document.querySelector('.page > .to-top-criterion')
      const footer = document.querySelector('#footer')

      const observer = new IntersectionObserver((entries) => {
          entries.forEach((item, i) => {

              // handle footer
              if (footer === item.target) {
                  if (!item.isIntersecting) {
                      btn.classList.remove('white')
                      return
                  }

                  btn.classList.add('white')
                  return
              }

              // only hide btn if test is below viewport
              if (item.boundingClientRect.top < 0) return
              if (!item.isIntersecting) {
                  btn.classList.add('transparent')
                  return
              }

              btn.classList.remove('transparent')
          });
      }, {threshold: [0.18]})

      observer.observe(test)
      observer.observe(footer)

      btn.addEventListener('click', () => {
          window.scrollTo(0, 0)
          btn.classList.add('transparent')
      })
  }

  initMenuSwitch () {
    const header = document.querySelector('#header')
    const btn = document.querySelector('#header .menu-switch')
    const nav = document.querySelector('#header #main-navigation')
    const className = 'mobile-menu-revealed'

    const clickCb = () => {
      header.classList.toggle(className)
      if (header.classList.contains(className)) {
        nav.hidden = false
        btn.ariaExpanded = 'true'
      } else {
        nav.hidden = true
        btn.ariaExpanded = 'false'
      }
    }

    btn.addEventListener('click', clickCb)
  }

  initSearchSwitch () {
    const btn = document.querySelector('#main-navigation .search-btn')
    const cross = document.querySelector('#main-navigation .search-input-container .search-cross')
    const input = document.querySelector('#main-navigation .search-input-container')

    const clickCb = () => {
      input.classList.toggle('revealed')
    }

    btn.addEventListener('click', clickCb)
    cross.addEventListener('click', clickCb)
  }

  initExpandInfo () {
    const btn = document.querySelector('.show-detail .info .expand')
    const expanded = document.querySelector('.show-detail .info .main-body #expanded')
    const info = document.querySelector('.show-detail .info')
    const className = 'expanded-revealed'

    btn.addEventListener('click', () => {
      info.classList.toggle(className)

      if (info.classList.contains(className)) {
        expanded.hidden = false
        btn.ariaExpanded = 'true'
      } else {
        expanded.hidden = true
        btn.ariaExpanded = 'false'
      }
    })
  }

  initMainSlider () {
    const swiper = new Swiper(document.querySelector('.main-slider'), {
        modules: [Pagination],
        pagination: {
            el: document.querySelector('.main-slider .bullets'),
            type: 'bullets',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active'
        }
    });

    console.log(swiper);
  }

  initSuccinctSlider () {
      const swiper = new Swiper(document.querySelector('.slides-container'), {
          modules: [Navigation],
          slidesPerView: 'auto',
          // spaceBetween: 100,
          navigation: {
              prevEl: document.querySelector('.succinct-slider .btn-prev'),
              nextEl: document.querySelector('.succinct-slider .btn-next'),
          },
          breakpoints: {
              0: {
                  spaceBetween: 0,
              },
              980: {
                  spaceBetween: 100
              }
          }
      });

      // if I don't update swiper, it sometimes moves the slides incorrectly (particularly, shifts them too much and irregularly to the left)
      swiper.on('beforeSlideChangeStart', () => {
          console.log('initSuccinctSlider, swiper beforeSlideChangeStart cb');
          swiper.update()
      })

      console.log('initSuccinctSlider, swiper:', swiper)
  }

  initPhotoScroll() {
      const container = document.querySelector('.photos-box')
      if (!container) return

      const spaceBetween = 8
      const btnPrev = document.querySelector('.photos-box .button-prev-container .button')
      const btnNext = document.querySelector('.photos-box .button-next-container .button')

      baguetteBox.run('.photos-container')

      if (!this.BriefList.prototype.isBehindRight(document.querySelector('html').clientWidth, Array.from(container.querySelectorAll('.photos-container .show-photo-container')).pop())) {
          container.querySelector('.button-prev-container').classList.add('noned')
          container.querySelector('.button-next-container').classList.add('noned')

          const swiper = new Swiper(container, {
              slidesPerView: 'auto',
              spaceBetween,
              enabled: false,
          })

          return
      }

      const swiper = new Swiper(container, {
          modules: [Navigation],
          slidesPerView: 'auto',
          freeMode: true,
          spaceBetween,
          navigation: {
              prevEl: btnPrev,
              nextEl: btnNext,
          }
      })

      // console.log('initPhotoScroll, swiper:', swiper);
  }

  initBriefList() {
      const container = document.querySelector('.brief-list-box')
      if (!container) return

      const content = container.querySelector('.brief-list')
      const btnPrev = document.querySelector('.brief-list-container .button-prev-container .button'),
      btnNext = document.querySelector('.brief-list-container .button-next-container .button')

      if (!this.BriefList.prototype.isBehindRight(document.querySelector('html').clientWidth, Array.from(content.querySelectorAll('.brief-container')).pop())) {
          document.querySelector('.brief-list-container .button-prev-container').classList.add('noned')
          document.querySelector('.brief-list-container .button-next-container').classList.add('noned')
          return
      }

      this.briefList = new this.BriefList()
  }

  // Turn headings into <details><summary>...</summary></details> on person
  initPersonBio () {
    const tpl = document.getElementById('actor-bio-tpl')
    const src = document.getElementById('actor-bio-raw')
    const headings = src.querySelectorAll('h2')
    const container = document.getElementById('actor-bio-wrapper')
    src.setAttribute('hidden', '')
    headings.forEach(h => {
      const _tpl = tpl.content.cloneNode(true)
      const tplBody = _tpl.querySelector('.content')
      const rest = this.getSiblingsUntilSameTag(h)
      _tpl.querySelector('.name').textContent = h.textContent
      rest.forEach(el => tplBody.appendChild(el))
      container.appendChild(_tpl)
    })
  }

  initShowDates () {
    const dates = document.querySelector('.date-pick')
    const ticketBtn = document.querySelector('.purchase')

    if (dates) {
      function dateChange (evt) {
        const ticketUrl = dates.selectedOptions[0].dataset.ticketUrl
        if (ticketUrl) {
          ticketBtn.removeAttribute('hidden')
          ticketBtn.setAttribute('href', ticketUrl)
        } else {
          ticketBtn.setAttribute('hidden', '')
        }
      }
      dates.addEventListener('change', dateChange)
    }
  }

  getViewportWidth () {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  }

  getSiblingsUntilSameTag (el) {
    const elements = []
    let next = el.nextSibling
    while (next && next.nodeName !== el.nodeName) {
      elements.push(next)
      next = next.nextSibling
    }
    return elements
  }
}

new FT()
